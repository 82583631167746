<template>
  <div class="page">
    <div class='body_slogan'>
      <div class="title">花样起名</div>
      <div class="content">
        <div class="round">
          <div class="desc">
            <span>好听的名字就像一幅山水画</span>
          </div>
        </div>
      </div>
    </div>
    <van-tabs v-model:active="which_page" color="orange" @change="click_tabs" sticky >
      <van-tab title="名字重要性"  title-style="color:orange;">
        <van-skeleton v-if="loading" :row="5" />
        <div v-else>
          <div v-html="worth_str"></div>
          <button  class="to_be_vip" data-value="group_worth|" @click="xml_go_to_goods_index">了解更多大数据起名功能</button>
        </div>
      </van-tab>
      <van-tab title="起名技巧"  title-style="color:orange;">
        <van-skeleton v-if="loading" :row="5" />
        <div v-else>
          <div v-html="how_str"></div>
          <button class="in_body black" data-value="index" @click="xml_go_to_only">试试看</button>
        </div>
      </van-tab>
    </van-tabs>
    <ul class="home_footer">
      <li class="icons"><router-link :to="{name:'index'}">首页</router-link></li>
      <li class="icons active_icons"><router-link :to="{name:'group_index'}">起名学堂</router-link></li>
      <li class="icons"><router-link :to="{name:'mine_index'}">我的</router-link></li>
    </ul>
    <van-popup v-model:show="show.zc" round position="bottom" :style="{ height: '72%' }" @close="on_close">
      <Login></Login>
    </van-popup>
  </div>
</template>

<script>
import store from '@/store'
import Utils from '@/utils/utils'
import axios from 'axios'

import Login from '@/components/other/Login'

export default {
  name: 'group_index',
  store,
  components: {
    Login
  },
  data () {
    return {
      show: { zc: false },
      loading: true,
      loading2: false,
      niming: store.state.niming,
      base_img: store.state.base_img,
      worth_str: '',
      how_str: '',
      which_page: 0
    }
  },

  mounted () {
    if (this.$route.query.tt === 1 || this.$route.query.tt === '1') {
      this.which_page = 1
    }
    Utils.set_local_sid(this.$route.query.sid)
    if (this.$route.query.sid && typeof this.$route.query.sid === 'string') {
      store.commit('log_sid', this.$route.query.sid)
      localStorage.setItem('sid', this.$route.query.sid)
    }
    this.get_content()
  },
  methods: {
    get_content () {
      if (this.which_page === 0 && !this.worth_str) {
        this.get_worth()
      } else if (this.which_page === 1 && !this.how_str) {
        this.get_how()
      }
    },
    click_tabs (e) {
      this.which_page = e
      this.get_content()
    },
    get_worth () {
      this.loading = true
      const data = { sid: this.$route.query.sid }
      const urlStr = !this.niming ? '/get_worth/' : '/get_worth_unlogin/'
      axios.post(urlStr, data)
        .then(res => {
          this.loading = false
          this.worth_str = res.data.data.worth_str
        })
    },
    get_how () {
      this.loading = true
      const data = { sid: this.$route.query.sid }
      const urlStr = !this.niming ? '/get_how/' : '/get_how_unlogin/'
      axios.post(urlStr, data)
        .then(res => {
          this.loading = false
          this.how_str = res.data.data.how_str
        })
    },
    xml_go_to_goods_index (e) {
      if (!localStorage.getItem('token')) {
        this.show.zc = true
        return
      }
      Utils.go_to_goods_index(e)
    },
    xml_go_to_only (e) {
      Utils.go_to(e)
    },
    on_close () {
      this.show = { zc: false }
    },
    pull_up (e) {
      this.show[e.currentTarget.dataset.value] = true
    }
  }
}
</script>

<style>
.van-tab__pane{
  text-align: left;
}

.section{
  padding: 0.5rem 0.5rem;
  margin: 0.5rem 0rem;
  background-color: #fefefe;
  font-size: 0.4rem;
  line-height: 0.6rem;
}

.section .title {
  text-align: center;
  color: #fefefe;
  width: 8rem;
  margin: 0.5rem auto;
  background:rgba(37, 130, 173, 0.3);
  font-size: 0.5rem;
  height: 0.8rem;
  line-height: 0.8rem;
  border-radius: 0.8rem;
}

.section .one_desc,.section .we_do{
  text-align: left;
  margin: 0.2rem 0rem;
}
.section .one_desc .p,.section .we_do .p{
  text-align: left;
  margin: 0.25rem 0rem;
}
.section .we_do {
  background: rgba(100, 184, 214,0.05);
  padding:0.2rem 0.2rem;
  border-radius: 0.2rem;
}
.section .we_do .p{
  color:rgba(37, 130, 173, 0.5);
}

.section .img{
  display: block;
  width: 8rem;
  margin: 0.4rem auto;
  box-shadow:0.02rem 0.02rem 0.1rem #888;
}

</style>
